<template>
  <div>
    <CampaignsSlideshow
      ratio="1800:600"
      v-if="
        campaigns.filter(
          (campaign) => campaign.display_location === 'store-index-top'
        ).length
      "
      :campaigns="
        campaigns.filter(
          (campaign) => campaign.display_location === 'store-index-top'
        )
      "
      :isLoading="isLoading"
    />
    <div class="uk-text-large" test="store-index-title">
      Productos Destacados
    </div>
    <div v-if="isLoading" class="uk-text-center">
      <div uk-spinner></div>
    </div>

    <div
      v-if="!isLoading && featuredProducts"
      class="
        uk-margin-small-top
        uk-child-width-1-2@s
        uk-child-width-1-3@m
        uk-child-width-1-4@l
        uk-grid-medium
        uk-grid-match
      "
      uk-grid
    >
      <div
        v-for="product in displayFeaturedProducts.slice(
          0,
          Math.floor(displayFeaturedProducts.length / 2)
        )"
        :key="product.id"
      >
        <ProductCard
          :product="product"
          :setActive="setActive"
          :showQuickview="false"
        />
      </div>

      <CampaignsSlideshow
        class="uk-width-1-1"
        ratio="1800:427.5"
        v-if="
          campaigns.filter(
            (campaign) => campaign.display_location === 'store-index-middle'
          ).length
        "
        :campaigns="
          campaigns.filter(
            (campaign) => campaign.display_location === 'store-index-middle'
          )
        "
        :isLoading="isLoading"
      />

      <div
        v-for="product in displayFeaturedProducts.slice(
          Math.floor(displayFeaturedProducts.length / 2)
        )"
        :key="product.id"
      >
        <ProductCard
          :product="product"
          :setActive="setActive"
          :showQuickview="false"
        />
      </div>

      <CampaignsSlideshow
        class="uk-width-1-1"
        ratio="1800:427.5"
        v-if="
          campaigns.filter(
            (campaign) => campaign.display_location === 'store-index-bottom'
          ).length
        "
        :campaigns="
          campaigns.filter(
            (campaign) => campaign.display_location === 'store-index-bottom'
          )
        "
        :isLoading="isLoading"
      />
    </div>

    <ProductQuickview :product="activeProduct" />
    <PopupModal :popups="popups" :isLoading="isLoading" />
  </div>
</template>

<script>
import ProductCard from "../Utils/ProductCard";
import CampaignsSlideshow from "../Utils/CampaignsSlideshow";
import PopupModal from "../Utils/PopupModal";
import ProductQuickview from "../Utils/ProductQuickview";
import UIkit from "uikit";
import { mapGetters } from "vuex";
export default {
  name: "StoreIndex",

  components: {
    ProductCard,
    CampaignsSlideshow,
    ProductQuickview,
    PopupModal,
  },

  data() {
    return {
      isLoading: true,
      popups: null,
      featuredProducts: null,
      activeProduct: null,
      campaigns: [],
    };
  },

  computed: {
    ...mapGetters(["storefrontStorefront"]),
    displayFeaturedProducts() {
      return this.featuredProducts.map((product) => {
        const startingAtQuantity = product.prices
          ? Math.min(...Object.keys(product.prices))
          : null;
        const startingAtPrice = startingAtQuantity
          ? product.prices[startingAtQuantity]
          : null;
        return {
          ...product,
          startingAtPrice,
          startingAtQuantity,
        };
      });
    },
  },

  mounted() {
    this.fetchProducts();
  },

  methods: {
    extractImage(product) {
      if (product.variations && product.variations.length !== 0) {
        const variationImage = product.variations
          .map((variation) => this.extractImage(variation))
          .filter((image) => image);
        if (variationImage.length !== 0) {
          return variationImage[0];
        }
      }
      if (product.children && product.children.length !== 0) {
        const childrenImage = product.children
          .map((children) => this.extractImage(children))
          .filter((image) => image);
        if (childrenImage.length !== 0) {
          return childrenImage[0];
        }
        return null;
      }
      if (
        this.storefrontStorefront.type === "b2b" &&
        product.media.length !== 0
      ) {
        return product.media[0];
      }
      if (
        this.storefrontStorefront.type === "redeem" &&
        product.storefront_redeemables.length !== 0 &&
        product.storefront_redeemables[0].media.length !== 0
      ) {
        return product.storefront_redeemables
          .map((storefrontRedeemable) => storefrontRedeemable.media)
          .flat()[0];
      }
      return null;
    },
    fetchProducts() {
      this.isLoading = true;
      this.axios
        .get("store")
        .then(({ data: { popups, featuredProducts, campaigns } }) => {
          this.popups = popups;
          this.featuredProducts = featuredProducts;
          this.campaigns = campaigns;

          if (this.popups && this.popups.length !== 0) {
            UIkit.modal("#popup-modal").show();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setActive(product) {
      this.activeProduct = product;
    },
    hidePopup(popupId) {
      this.axios
        .post("/hide-popup", {
          popup_id: popupId,
        })
        .then(() => {
          this.popups = this.popups.filter((popup) => popup.id !== popupId);
          if (this.popups.length === 0) {
            UIkit.modal("#popup-modal").hide();
          }
        });
    },
  },
};
</script>
